import { t } from "i18next";
import {
  Checkbox,
  Col,
  Collapse,
  CollapseProps,
  Divider,
  Form,
  Input,
  Row,
} from "antd";
import { Fragment } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const Second = ({ form }: any) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleCheckboxChange = useCallback(
    (e: CheckboxChangeEvent) => {
      setIsExpanded(e.target.checked);
    },

    [setIsExpanded]
  );

  const handleCollapseChange = (key: string | string[]) => {
    setIsExpanded(key.includes("1"));
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: t("shoppingCart.step2.deliveryData"),
      children: <DeliveryForm form={form} />,
    },
  ];
  const items2: CollapseProps["items"] = [
    {
      key: "1",
      label: t("shoppingCart.step2.billingData"),
      children: <p>Fakturacni udaje</p>,
    },
  ];

  return (
    <Fragment>
      <Divider type="horizontal" style={{ width: "100%" }} />
      <Collapse items={items} defaultActiveKey={["1"]} />
      <Checkbox
        style={{ margin: "10px 0" }}
        onChange={handleCheckboxChange}
        checked={isExpanded}
      >
        {t("shoppingCart.step2.billingIsNotAsDeliver")}
      </Checkbox>
      <Collapse
        items={items2}
        activeKey={!isExpanded ? ["1"] : []}
        onChange={handleCollapseChange}
      />
      <Divider />
    </Fragment>
  );
};

export default Second;

const DeliveryForm = ({ form }: any) => {
  //   const [form] = Form.useForm();
  // TODO: input tel
  return (
    <Form form={form} layout="vertical">
      <Row gutter={24}>
        <Col span={24} sm={12}>
          <Form.Item
            layout="vertical"
            label={t("shoppingCart.step2.name")}
            name={"name"}
            id="name"
            rules={[
              {
                required: true,
                message: t("shoppingCart.step2.formValidation.requireName"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} sm={12}>
          <Form.Item
            layout="vertical"
            label={t("shoppingCart.step2.surname")}
            name={"surname"}
            rules={[
              {
                required: true,
                message: t("shoppingCart.step2.formValidation.requireSurname"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} sm={18}>
          <Form.Item
            layout="vertical"
            label={t("shoppingCart.step2.street")}
            name={"street"}
            rules={[
              {
                required: true,
                message: t("shoppingCart.step2.formValidation.requireStreet"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} sm={6}>
          <Form.Item
            layout="vertical"
            label={t("shoppingCart.step2.aptNumber")}
            name={"aptNumber"}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} sm={12} md={8}>
          <Form.Item
            layout="vertical"
            label={t("shoppingCart.step2.city")}
            name={"city"}
            rules={[
              {
                required: true,
                message: t("shoppingCart.step2.formValidation.requireCity"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={6} span={24}>
          <Form.Item
            layout="vertical"
            label={t("shoppingCart.step2.zipCode")}
            name={"zip"}
            rules={[
              {
                required: true,
                message: t("shoppingCart.step2.formValidation.requireZip"),
              },
              {
                pattern: /^\d{5}$/,
                message: t(
                  "shoppingCart.step2.formValidation.numberOfDigitsZip"
                ),
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
