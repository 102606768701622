import { atom } from "recoil";
import { localStorageEffect } from "./storagePersistance";

export enum StepStatus {
  Error = "error",
  Wait = "wait",
  Process = "process",
  Finish = "finish",
}

type UserProps = {
  displayName: string;
  photoURL: string;
  accessToken: string;
};

export const loggedUser = atom<UserProps | null>({
  key: "user",
  default: null,
  effects: [localStorageEffect("user")],
});
