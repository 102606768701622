import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { lazy, ReactNode, Suspense } from "react";
import MenuBar from "./containers/MenuBar";
import { Spin } from "antd";
import { defaultTheme } from "./theme";
import styled, { ThemeProvider } from "styled-components";
import ShoppingCart from "./pages/ShoppingCart";
import { RecoilRoot } from "recoil";
import { MenuItems } from "./state/menu";
import Winners from "./pages/Winners";
import AboutUs from "./pages/AboutUs";
import Auctions from "./pages/Auctions";
import Footer from "./containers/Footer";

const HomePage = lazy(() => import("./pages/HomePage"));
const Login = lazy(() => import("./pages/Login"));

interface LayoutProps {
  children: ReactNode;
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;
const Content = styled.div`
  flex: 1;
`;

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Main>
      <MenuBar />
      <Content>{children}</Content>
      <Footer />
    </Main>
  );
};

const App = () => {
  return (
    <RecoilRoot>
      <ThemeProvider theme={defaultTheme}>
        <Router>
          <Suspense
            fallback={
              <Spin
                size="large"
                fullscreen
                spinning={true}
                tip="Načítání aplikace..."
              />
            }
          >
            <Routes>
              <Route
                path="/"
                element={
                  <Layout>
                    <HomePage />
                  </Layout>
                }
              />
              <Route
                path={`/${MenuItems.Auctions}`}
                element={
                  <Layout>
                    <Auctions />
                  </Layout>
                }
              />
              <Route
                path={`/${MenuItems.Winners}`}
                element={
                  <Layout>
                    <Winners />
                  </Layout>
                }
              />
              <Route
                path={`/${MenuItems.AboutUs}`}
                element={
                  <Layout>
                    <AboutUs />
                  </Layout>
                }
              />
              <Route
                path={`/${MenuItems.ShoppingCart}`}
                element={
                  <Layout>
                    <ProtectedRoute>
                      <ShoppingCart />
                    </ProtectedRoute>
                  </Layout>
                }
              />
              {/* Login page*/}
              <Route
                path={`/${MenuItems.LogIn}`}
                element={
                  <Layout>
                    <Login />
                  </Layout>
                }
              />
              {/* Fallback route for any undefined routes */}
              {/* <Route path="*" element={<NotFound />} /> */}
            </Routes>
          </Suspense>
        </Router>
      </ThemeProvider>
    </RecoilRoot>
  );
};

const isAuthenticated = (): boolean => {
  return localStorage.getItem("isAuth") === "true" ? true : false;
};

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  return isAuthenticated() ? <>{children}</> : <Navigate to="/login" />;
};

export default App;
