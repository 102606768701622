import { Divider } from "antd";
import { styled, useTheme } from "styled-components";
import Row from "../containers/Row";
import { StyledTitle } from "../components/Typography";
import { t } from "i18next";
import { formatPrice } from "../helpers/textTransformation";
import Stepper from "../containers/Stepper";
import { useRecoilValue } from "recoil";
import { cartProduct } from "../state/shoppingCart";

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    width: 65%;
  }
`;
const RightPanel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 4px 8px 0px #00154015;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 24px;
  justify-content: space-between;
  @media (min-width: 768px) {
    width: 25%;
  }
`;
const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ShoppingCart = () => {
  const theme = useTheme();
  const product = useRecoilValue(cartProduct);

  if (!product)
    return (
      <Row>
        <StyledTitle>{t("shoppingCart.emptyBasket")}</StyledTitle>
      </Row>
    );

  return (
    <Row>
      <ProductWrapper>
        <LeftPanel>
          <Stepper />
        </LeftPanel>
        <RightPanel>
          <InformationWrapper>
            <div>{t("shoppingCart.productName")}: </div>
            <br />
            <div>
              <b>{product.title}</b>
            </div>
            <Divider />
            <div>{t("auction.description")}: </div>
            <br />
            <div>
              <b>{product.description}</b>
            </div>
            <Divider />
            <div>{t("auction.price")}: </div>
            <div>
              <StyledTitle style={{ color: theme.colors.primary }}>
                {formatPrice(product.price)}
              </StyledTitle>
            </div>
          </InformationWrapper>
        </RightPanel>
      </ProductWrapper>
    </Row>
  );
};

export default ShoppingCart;
