import { atom } from "recoil";
import { localStorageEffect } from "./storagePersistance";

export enum MenuItems {
  Home = "home",
  ShoppingCart = "shopping-cart",
  AboutUs = "about-us",
  Winners = "winners",
  Auctions = "auctions",
  LogOut = "logout",
  LogIn = "login",
}

export const selectedMenu = atom<MenuItems>({
  key: "menu",
  default: MenuItems.Home,
  effects: [localStorageEffect("menu")],
});
