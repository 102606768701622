export const formatDate = (dateIso: string) => {
  const date = new Date(dateIso);
  return date.toLocaleDateString("cz-CZ", {
    day: "numeric",
    year: "numeric",
    month: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
};
export const addMissingProtocol = (url: string | undefined) => {
  // Regular expression to check if the URL starts with http:// or https://
  const hasProtocol = /^https?:\/\//i;

  // If the URL doesn't have a protocol, prepend https://
  if (url && !hasProtocol.test(url)) {
    return `https://${url}`;
  }

  // If the protocol exists, return the URL as is
  return url;
};

export const formatPrice = (price: number) => {
  return new Intl.NumberFormat("cs-CZ", {
    style: "currency",
    currency: "CZK",
  }).format(price);
};
