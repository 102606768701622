import { styled } from "styled-components";
import { OutlineOrangeButton, StyledButton } from "../components/Typography";
import { useState } from "react";
import { Flex, Form, Steps } from "antd";
import { t } from "i18next";
import First from "./CartStepsContent/First";
import Second from "./CartStepsContent/Second";

const StyledSteps = styled(Steps)`
  background-color: ${({ theme }) => theme.colors.grey100} !important;
  border-radius: ${({ theme }) => theme.borderRadius} !important;
  padding: 16px;
  .ant-steps {
  }
  .ant-steps-icon {
    color: ${({ theme }) => theme.colors.white} !important;
  }
  .ant-steps-item-title {
    font-weight: 700;
  }
  .ant-steps-item-icon {
    background-color: ${({ theme }) => theme.colors.blue500} !important;
    border-color: ${({ theme }) => theme.colors.blue500} !important;
  }
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: ${({ theme }) => theme.colors.white} !important;
      border: none;
      /* border-color: ${({ theme }) => theme.colors.primary} !important; */
      box-shadow: 0px 1px 2px 0px #1f293714;
      > span {
        color: ${({ theme }) => theme.colors.blue500} !important;
      }
    }
  }
  .ant-steps-item-title {
    color: ${({ theme }) => theme.colors.blue500} !important;
  }
  .ant-steps-item-finish {
    .ant-steps-item-title {
      color: ${({ theme }) => theme.colors.primary} !important;
      &:after {
        height: 2px;
        background-color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
    .ant-steps-item-icon {
      background-color: ${({ theme }) => theme.colors.primary} !important;
      border-color: ${({ theme }) => theme.colors.primary} !important;
    }
    .ant-steps-item-tail {
      &::after {
        background-color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  }
`;
const StepsNav = styled.div`
  margin-bottom: 20px;
`;
const ButtonWrapper = styled.div`
  width: auto;
  align-self: flex-start;
`;

const Stepper = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();

  const next = async () => {
    window.scrollTo({ top: 0 });
    if (currentStep === 1) {
      try {
        await form.validateFields();
      } catch (error) {
        console.error("Validation failed:", error);
        return; // Prevent moving to the next step if validation fails
      }
    }
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const finish = () => {
    setCurrentStep(3);
  };

  const finishInformation = () => {};

  const steps = [
    {
      title: t("shoppingCart.step1.title"),
      content: <First />,
      finish: finishInformation,
    },
    {
      title: t("shoppingCart.step2.title"),
      content: <Second form={form} />,
    },
    {
      title: t("shoppingCart.step3.title"),
      content: <div>3333</div>,
    },
    {
      title: t("shoppingCart.step4.title"),
      content: <div>4444</div>,
    },
  ];

  return (
    <>
      <StyledSteps
        current={currentStep}
        items={steps}
        size="small"
        direction="horizontal"
        initial={0}
      />
      <StepsNav>{steps[currentStep].content}</StepsNav>
      <Flex dir="row" justify="flex-end" gap={12}>
        {currentStep > 0 && (
          <ButtonWrapper>
            <OutlineOrangeButton onClick={prev}>
              {t("shoppingCart.prev")}
            </OutlineOrangeButton>
          </ButtonWrapper>
        )}
        {currentStep < steps.length - 1 && (
          <ButtonWrapper>
            <StyledButton onClick={next}>{t("shoppingCart.next")}</StyledButton>
          </ButtonWrapper>
        )}
        {currentStep === steps.length - 1 && (
          <ButtonWrapper>
            <StyledButton onClick={finish}>
              {t("shoppingCart.finish")}
            </StyledButton>
          </ButtonWrapper>
        )}
      </Flex>
    </>
  );
};

export default Stepper;
