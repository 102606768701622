import { styled } from "styled-components";
import { Button } from "./Button";

export const StyledTitle = styled.h1`
  line-height: 1;
  font-weight: 900;
  font-size: 20px;
  @media (min-width: 768px) {
    font-size: 26px;
  }
  @media (min-width: 1024px) {
    font-size: 34px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const OutlineOrangeButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.text};
    color: ${({ theme }) => theme.colors.text};
  }
`;
