import { FC, PropsWithChildren } from "react";
import { styled } from "styled-components";

type RowProps = {
  fullWidth?: boolean;
};

type StyledRowProps = {
  fullwidth?: string;
};

const StyledRow = styled.div<StyledRowProps>`
  display: flex;
  padding: ${(props) => (props.fullwidth ? "0" : "0 20px")};

  @media (min-width: 768px) {
    padding: ${(props) => (props.fullwidth ? "0" : "0 80px")};
  }
  @media (min-width: 1248px) {
    padding: ${(props) => (props.fullwidth ? "0" : "0 160px")};
  }
`;

const Row: FC<PropsWithChildren<RowProps>> = ({ fullWidth, children }) => {
  return <StyledRow fullwidth={fullWidth?.toString()}>{children}</StyledRow>;
};

export default Row;
