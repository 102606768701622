import { useEffect, useState } from "react";
import axios from "axios";

export type Newsletter = {
  email: string;
};

export default function useNewsletter() {
  const [loading, setLoading] = useState(false);
  // const [auction, setAuctions] = useState<ParsedAuction | null>(null);

  const addNewsletter = async (email: string | undefined) => {
    try {
      setLoading(true);
      axios.post<Newsletter>(`https://trefimto.bauerdavid.com/api/newsletter`, {
        email: email,
      });
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  return { loading, addNewsletter };
}
