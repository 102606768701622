import { styled } from "styled-components";
import Row from "./Row";
import { Link } from "react-router-dom";
import { Flex, Image, Input } from "antd";
import Logo from "../assets/logo.svg";
import { useRecoilState } from "recoil";
import { MenuItems, selectedMenu } from "../state/menu";
import fb from "../assets/fb.svg";
import ig from "../assets/ig.svg";
import { StyledButton } from "../components/Typography";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import { t } from "i18next";
import useNewsletter from "../hooks/useNewsletter";

const FooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.blue900};
  width: 100%;
  margin-top: 80px;
  padding-top: 100px;
  padding-bottom: 40px;
  color: ${({ theme }) => theme.colors.white};
`;

const pages = [
  { name: "O nás", link: "/" },
  { name: "Kontakt", link: "/" },
  { name: "Obchodní podmínky", link: "/" },
  { name: "Ochrana osobních údajů", link: "/" },
];

const Footer = () => {
  const [, setActiveMenu] = useRecoilState(selectedMenu);
  const [email, setEmail] = useState("");
  const { loading, addNewsletter } = useNewsletter();

  console.log("email", email);

  const onSendEmail = useCallback(() => {
    addNewsletter(email);
    setEmail("");
  }, [email]);

  return (
    <Row fullWidth={true}>
      <FooterWrapper>
        <Row>
          <Flex
            style={{ width: "100%", justifyContent: "space-between" }}
            gap={40}
          >
            <Flex vertical gap={40}>
              <Link to="/" onClick={() => setActiveMenu(MenuItems.Home)}>
                <Image src={Logo} preview={false} height={48} />
              </Link>
              <Flex align="center" gap={34}>
                <Link to="https://www.facebook.com/trefim.to/" target="_blank">
                  <Image src={fb} preview={false} height={18} />
                </Link>
                <Link to="https://www.instagram.com/trefim_to/" target="_blank">
                  <Image src={ig} preview={false} height={18} />
                </Link>
              </Flex>
              <Flex gap={12}>
                <Input
                  style={{ height: 44 }}
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <StyledButton style={{ maxWidth: 44 }} onClick={onSendEmail}>
                  <ArrowRightOutlined />
                </StyledButton>
              </Flex>
            </Flex>
            <Flex vertical>
              <div style={{ fontSize: 18, fontWeight: 400, marginBottom: 14 }}>
                {t("footer.pages").toUpperCase()}
              </div>
              {pages.map((page) => (
                <Link
                  to={page.link}
                  style={{ fontSize: 16, fontWeight: 400, lineHeight: "37px" }}
                >
                  {page.name}
                </Link>
              ))}
            </Flex>
            <Flex vertical>
              <div style={{ fontSize: 18, fontWeight: 400, marginBottom: 14 }}>
                {t("footer.support").toUpperCase()}
              </div>
              {pages.map((page) => (
                <Link
                  to={page.link}
                  style={{ fontSize: 16, fontWeight: 400, lineHeight: "37px" }}
                >
                  {page.name}
                </Link>
              ))}
            </Flex>
          </Flex>
        </Row>
      </FooterWrapper>
    </Row>
  );
};

export default Footer;
