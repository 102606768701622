import { useRecoilState } from "recoil";
import { OutlineOrangeButton } from "../../components/Typography";
import { t } from "i18next";
import { Divider, Flex, Image, Modal, Typography } from "antd";
import { Fragment } from "react/jsx-runtime";
import { useTheme } from "styled-components";
import { cartProduct } from "../../state/shoppingCart";
import { useMediaQuery } from "react-responsive";
import { useCallback, useState } from "react";

const First = () => {
  const [product, setProduct] = useRecoilState(cartProduct);
  const [modalLogoutActive, setModalLogoutActive] = useState<boolean>(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  const removeProduct = useCallback(() => {
    setProduct(null);
  }, [setProduct]);

  const toggleModal = useCallback(
    (event: boolean) => () => {
      setModalLogoutActive(event);
    },
    [setModalLogoutActive]
  );

  return (
    <Fragment>
      <Divider type="horizontal" style={{ width: "100%" }} />
      <Flex vertical={!isDesktop} justify="space-between" gap={20}>
        <Flex gap={20} vertical={!isDesktop}>
          <Image src={product?.photoUrls[0]} width={isDesktop ? 150 : "100%"} />
          <Flex vertical gap={5}>
            <Typography>
              <b>{product?.title}</b>
            </Typography>
            <span>{product?.description}</span>
          </Flex>
        </Flex>
        <Flex vertical={false} align="center" gap={10}>
          <OutlineOrangeButton onClick={toggleModal(true)}>
            <span>{t("shoppingCart.step1.remove")}</span>
          </OutlineOrangeButton>
        </Flex>
      </Flex>
      <Divider />
      <Modal
        open={modalLogoutActive}
        okButtonProps={{ style: { backgroundColor: theme.colors.primary } }}
        onClose={toggleModal(false)}
        onCancel={toggleModal(false)}
        cancelText={t("casual.no")}
        okText={<div>{t("casual.yes")}</div>}
        title={t("shoppingCart.step1.removeConfirm")}
        onOk={removeProduct}
      />
    </Fragment>
  );
};

export default First;
