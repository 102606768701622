import { Modal } from "antd";
import { Fragment, useCallback, useState } from "react";
import { styled, useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "./Typography";
import { t } from "i18next";
import { signOut } from "firebase/auth";
import { auth } from "../authorization/firebase";
import { useRecoilState } from "recoil";
import { loggedUser } from "../state/loggedUser";
import { isMobileMenuOpenState } from "../state/isMobileMenuOpen";

const StyledLogout = styled(StyledButton)`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const LogoutButton = () => {
  const [modalLogoutActive, setModalLogoutActive] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loggedUser);
  const [, setIsMobileMenuOpen] = useRecoilState(isMobileMenuOpenState);
  const theme = useTheme();
  const navigate = useNavigate();

  const onLogOut = useCallback(() => {
    setIsMobileMenuOpen(false);
    navigate("/login");
  }, [navigate, setIsMobileMenuOpen]);

  const toggleModal = useCallback(
    (event: boolean) => () => {
      setModalLogoutActive(event);
    },
    [setModalLogoutActive]
  );

  const handleSignOut = useCallback(() => {
    setModalLogoutActive(false);
    setIsMobileMenuOpen(false);
    localStorage.setItem("isAuth", "false");
    setIsLoggedIn(null);
    localStorage.removeItem("user");
    signOut(auth);
    navigate("/");
  }, [navigate, setIsLoggedIn, setIsMobileMenuOpen]);

  return (
    <Fragment>
      <StyledLogout onClick={isLoggedIn ? toggleModal(true) : onLogOut}>
        {isLoggedIn ? t("auth.logout") : t("auth.login")}
      </StyledLogout>
      <Modal
        open={modalLogoutActive}
        okButtonProps={{ style: { backgroundColor: theme.colors.primary } }}
        onClose={toggleModal(false)}
        onCancel={toggleModal(false)}
        cancelText={t("casual.cancel")}
        okText={<div>{t("auth.logout")}</div>}
        title={t("auth.logoutConfirm")}
        onOk={handleSignOut}
      />
    </Fragment>
  );
};

export default LogoutButton;
